export const REPAIR_360 = 'Repair 360';
export const USERS = 'Users';
export const USER = 'User';
export const ACCOUNT_DASHBOARD = 'Account Dashboard';
export const REPORT_CENTER = 'Report Center';
export const MANAGE_DEVICES = 'Manage Devices';
export const RESOURCES = 'Resources';
export const GOOGLE_ADMIN = 'Google Admin';
export const MY_PROFILE = 'My Profile';
export const SETTINGS = 'Settings';
export const FEATURES = 'Features';
export const DASHBOARD_COLOR = 'Dashboard Color';
export const VIVACITY_ADMIN_PAGE = 'Vivacity Admin';
export const SIDEBAR = 'SIDEBAR';
export const SUPPORT = 'SUPPORT';
export const MY_BUILDINGS = 'My Buildings';
export const ESTIMATES_ORDERS = 'Estimates & Orders';
export const ACCOUNT_CREDITS = 'Account Credits';

export const SUPER_ADMIN = 'Super Admin';
export const SCHOOL_DISTRICT_ADMIN = 'School District Admin';
export const BUILDING_ADMIN = 'Building Admin';
export const REPAIR_TECH = 'Repair Tech';
export const FACILITATOR = 'Facilitator';
export const SUPER_STUDENT = 'Super Student';
export const ADVANCED_STUDENT = 'Advanced Student';
export const STUDENT = 'Student';
export const VIVACITY_ADMIN = 'Vivacity Admin';
export const VIVACITY_DOMAIN = 'vivacitytech.com';
export const STUDENT_REPAIR_TECHNICIAN = 'Student Repair Technician';
export const LOANER_MANAGER = 'Loaner Manager';
export const PRACTICE_STUDENT = 'Practice Student';

export const ROLE_ACTIONS = {
  CREATE: 'Create',
  VIEW: 'View',
  MODIFY: 'Modify',
  CREATE_TICKET: 'Create Ticket',
  CHECK_IN_OUT: 'Check In/Out',
  CREATE_STUDENT: 'Create Student',
};
