import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import { isAPIResponseFailure } from 'src/api/base';
import appLogger from 'src/infrastructure/config/appLogger';
import { RootState } from 'src/store';
import {
  getEstimates,
  getEstimatesByAccount,
  getInvoices,
  getInvoicesByAccount,
  getQuotes,
  getQuotesByAccount,
  getSalesOrder,
  getSalesOrderByAccount,
} from 'src/api/estimatesOrders';
import type { Order, Estimates, Quote, Quotes, Invoice, Invoices } from 'src/api/estimatesOrders';
import { get } from 'lodash';
import { setLoading } from './commonSlice';

type InitialState = {
  estimates: Order[];
  quotes: Quote[];
  orders: Order[];
  invoices: Invoice[];
};

const initialState: InitialState = {
  estimates: [],
  quotes: [],
  orders: [],
  invoices: [],
};
const sliceName = 'estimatesOrders';

export const fetchAllEstimates = createAsyncThunk<Estimates, { token: string }>(
  `${sliceName}/estimates/fetchAll`,
  async (params, ThunkAPI) => {
    const loaderName = `${sliceName}/estimates/fetch`;
    ThunkAPI.dispatch(setLoading([loaderName, true]));
    try {
      const estimates = await getEstimates(params);
      ThunkAPI.dispatch(setLoading([loaderName, false]));
      if (isAPIResponseFailure(estimates)) {
        return ThunkAPI.rejectWithValue(estimates);
      }
      return estimates.data;
    } catch (e) {
      appLogger.error(e);
    } finally {
      ThunkAPI.dispatch(setLoading([loaderName, false]));
    }
    return [];
  }
);

export const fetchAllEstimatesByAccount = createAsyncThunk<Estimates, { token: string; accountNumber: number }>(
  `${sliceName}/estimates/fetchAllByAccount`,
  async (params, ThunkAPI) => {
    const loaderName = `${sliceName}/estimates/fetch`;
    ThunkAPI.dispatch(setLoading([loaderName, true]));
    try {
      const estimates = await getEstimatesByAccount(params);
      ThunkAPI.dispatch(setLoading([loaderName, false]));
      if (isAPIResponseFailure(estimates)) {
        return ThunkAPI.rejectWithValue(estimates);
      }
      return estimates.data;
    } catch (e) {
      appLogger.error(e);
    } finally {
      ThunkAPI.dispatch(setLoading([loaderName, false]));
    }
    return [];
  }
);

export const fetchAllQuotes = createAsyncThunk<Quotes, { token: string }>(
  `${sliceName}/quotes/fetchAll`,
  async (params, ThunkAPI) => {
    const loaderName = `${sliceName}/quotes/fetch`;
    ThunkAPI.dispatch(setLoading([loaderName, true]));
    try {
      const quotes = await getQuotes(params);
      ThunkAPI.dispatch(setLoading([loaderName, false]));
      if (isAPIResponseFailure(quotes)) {
        return ThunkAPI.rejectWithValue(quotes);
      }
      return quotes.data;
    } catch (e) {
      appLogger.error(e);
    } finally {
      ThunkAPI.dispatch(setLoading([loaderName, false]));
    }
    return [];
  }
);

export const fetchAllQuotesByAccount = createAsyncThunk<Quotes, { token: string; accountNumber: number }>(
  `${sliceName}/quotes/fetchAllByAccount`,
  async (params, ThunkAPI) => {
    const loaderName = `${sliceName}/quotes/fetch`;
    ThunkAPI.dispatch(setLoading([loaderName, true]));
    try {
      const quotes = await getQuotesByAccount(params);
      ThunkAPI.dispatch(setLoading([loaderName, false]));
      if (isAPIResponseFailure(quotes)) {
        return ThunkAPI.rejectWithValue(quotes);
      }
      return quotes.data;
    } catch (e) {
      appLogger.error(e);
    } finally {
      ThunkAPI.dispatch(setLoading([loaderName, false]));
    }
    return [];
  }
);

export const fetchAllSalesOrder = createAsyncThunk<Estimates, { token: string }>(
  `${sliceName}/sales_order/fetchAll`,
  async (params, ThunkAPI) => {
    const loaderName = `${sliceName}/sales_order/fetch`;
    ThunkAPI.dispatch(setLoading([loaderName, true]));
    try {
      const orders = await getSalesOrder(params);
      ThunkAPI.dispatch(setLoading([loaderName, false]));
      if (isAPIResponseFailure(orders)) {
        return ThunkAPI.rejectWithValue(orders);
      }
      return orders.data;
    } catch (e) {
      appLogger.error(e);
    } finally {
      ThunkAPI.dispatch(setLoading([loaderName, false]));
    }
    return [];
  }
);

export const fetchAllSalesOrderByAccount = createAsyncThunk<Estimates, { token: string; accountNumber: number }>(
  `${sliceName}/sales_order/fetchAllByAccount`,
  async (params, ThunkAPI) => {
    const loaderName = `${sliceName}/sales_order/fetch`;
    ThunkAPI.dispatch(setLoading([loaderName, true]));
    try {
      const orders = await getSalesOrderByAccount(params);
      ThunkAPI.dispatch(setLoading([loaderName, false]));
      if (isAPIResponseFailure(orders)) {
        return ThunkAPI.rejectWithValue(orders);
      }
      return orders.data;
    } catch (e) {
      appLogger.error(e);
    } finally {
      ThunkAPI.dispatch(setLoading([loaderName, false]));
    }
    return [];
  }
);

export const fetchAllInvoices = createAsyncThunk<Invoices, { token: string }>(
  `${sliceName}/invoices/fetchAll`,
  async (params, ThunkAPI) => {
    const loaderName = `${sliceName}/invoices/fetch`;
    ThunkAPI.dispatch(setLoading([loaderName, true]));
    try {
      const orders = await getInvoices(params);
      ThunkAPI.dispatch(setLoading([loaderName, false]));
      if (isAPIResponseFailure(orders)) {
        return ThunkAPI.rejectWithValue(orders);
      }
      return orders.data;
    } catch (e) {
      appLogger.error(e);
    } finally {
      ThunkAPI.dispatch(setLoading([loaderName, false]));
    }
    return [];
  }
);

export const fetchAllInvoicesByAccount = createAsyncThunk<Invoices, { token: string; accountNumber: number }>(
  `${sliceName}/invoices/fetchAllByAccount`,
  async (params, ThunkAPI) => {
    const loaderName = `${sliceName}/invoices/fetch`;
    ThunkAPI.dispatch(setLoading([loaderName, true]));
    try {
      const orders = await getInvoicesByAccount(params);
      ThunkAPI.dispatch(setLoading([loaderName, false]));
      if (isAPIResponseFailure(orders)) {
        return ThunkAPI.rejectWithValue(orders);
      }
      return orders.data;
    } catch (e) {
      appLogger.error(e);
    } finally {
      ThunkAPI.dispatch(setLoading([loaderName, false]));
    }
    return [];
  }
);

const estimatesOrdersSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchAllEstimates.fulfilled, (state, action) => {
      const { payload } = action;
      state.estimates = get(payload, 'data', []);
    });

    builder.addCase(fetchAllEstimatesByAccount.fulfilled, (state, action) => {
      const { payload } = action;
      state.estimates = get(payload, 'data', []);
    });

    builder.addCase(fetchAllQuotes.fulfilled, (state, action) => {
      const { payload } = action;
      state.quotes = get(payload, 'data', []);
    });

    builder.addCase(fetchAllQuotesByAccount.fulfilled, (state, action) => {
      const { payload } = action;
      state.quotes = get(payload, 'data', []);
    });

    builder.addCase(fetchAllSalesOrder.fulfilled, (state, action) => {
      const { payload } = action;
      state.orders = get(payload, 'data', []);
    });

    builder.addCase(fetchAllSalesOrderByAccount.fulfilled, (state, action) => {
      const { payload } = action;
      state.orders = get(payload, 'data', []);
    });

    builder.addCase(fetchAllInvoices.fulfilled, (state, action) => {
      const { payload } = action;
      state.invoices = get(payload, 'data', []);
    });

    builder.addCase(fetchAllInvoicesByAccount.fulfilled, (state, action) => {
      const { payload } = action;
      state.invoices = get(payload, 'data', []);
    });
  },
});

export const estimatesOrdersSelector = createSelector(
  (state: RootState) => state.estimatesOrders,
  (items: InitialState) => items
);

export default estimatesOrdersSlice.reducer;
