import { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Navigate } from 'react-router-dom';
import { getBooleanFeatureFlagValue } from 'src/utils/LaunchDarklyQuery';
import { useAppSelector } from 'src/store/hooks';
import { accountStateItem } from 'src/slices/accountSlice';
import useRoleIdentity, { PAGE_NAMES } from '../roles/hooks';
import { FORBIDDEN_ACCESS } from '../../constants/routes';

type Props = {
  name?: string | null;
  children: JSX.Element;
};

const ProtectedRoute = ({ name = null, children }: Props) => {
  const { isAuthenticated, isLoading, loginWithRedirect } = useAuth0();
  const { accountDetails } = useAppSelector(accountStateItem);
  const identityCheck = useRoleIdentity(name);
  const [ldEstimatesOrdersView, setLdEstimatesOrdersView] = useState<boolean | null>(null);

  const hasInvalidNetsuiteId = accountDetails && Number.isNaN(parseInt(accountDetails.netsuite_id, 10));
  const isEstimatesOrdersPage = name === PAGE_NAMES.ESTIMATES_ORDERS;
  const lackingEstimatesOrdersPermission = !ldEstimatesOrdersView;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const EstimatesOrdersView = await getBooleanFeatureFlagValue('dream-feature-transactions');
        setLdEstimatesOrdersView(EstimatesOrdersView);
      } catch (error) {
        setLdEstimatesOrdersView(false);
      }
    };
    fetchData();
  }, []);

  if (isLoading || ldEstimatesOrdersView === null) return null;

  if (!isAuthenticated) {
    loginWithRedirect();
    return null;
  }

  if (isEstimatesOrdersPage && (lackingEstimatesOrdersPermission || hasInvalidNetsuiteId)) {
    return <Navigate to={FORBIDDEN_ACCESS} />;
  }

  if (name && isAuthenticated && !identityCheck.canViewPage()) return <Navigate to={FORBIDDEN_ACCESS} />;

  return children;
};

export default ProtectedRoute;
